import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import GDPRPageTemplate from "./GDPRPageTemplate"

const GDPRPage = ({ data }) => {
  const { frontmatter: fm } = data.markdownRemark
  return (
    <>
      <MyHelmet title={fm.title} description="" />
      <GDPRPageTemplate
        heading={fm.title}
        subheading={fm.subheading}
        html={data.markdownRemark.html}
      />
    </>
  )
}

export default GDPRPage

export const gdprQuery = graphql`
  query GDPR($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
      }
    }
  }
`
