import React from "react"
import Content from "../components/Content"
import { Container } from "../components/Sections"
import Header from "../components/Header"

const GDPRPageTemplate = ({ heading, subheading, html }) => {
  return (
    <>
      <Header heading={heading} subheading={subheading} />
      <Container>
        <Content html={html} />
      </Container>
    </>
  )
}

export default GDPRPageTemplate
